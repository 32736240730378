.boxCard {
  @apply w-full  md:w-[48.5%] p-[20px] md:p-[24px]
  rounded-2xl flex flex-col justify-between transition ease-in-out delay-150 bg-none md:bg-gradient-to-t 
  from-[#1e2027] to-[#121419];

  &:hover {
    .boxCardContainer {
      @apply md:scale-105;
    }
  }

  .boxCardContainer {
    @apply w-full bg-transparent transition ease-in-out delay-150 duration-700;
  }
  .boxHeader {
    @apply flex items-center gap-2;
    // @apply flex flex-col md:flex-row xl:flex-col ;
  }
  .iconBox {
    @apply relative;
  }

  .title {
    @apply text-[#29D7DF] font-extralight mt-[14px] mb-[13px]  md:ml-4 xl:ml-0;
  }

  .boxCardItem {
    @apply flex justify-start items-center gap-[12px] mt-1 relative;

    .circle-green {
      @apply w-[6px] h-[6px] rounded-sm bg-[#29D7DF] rotate-45;
      box-shadow: 0px 0px 16px -1px #29d7df;
    }
    .circle-yellow {
      @apply w-[6px] h-[6px] rounded-sm bg-[#d9ce6a] rotate-45 absolute  top-4;
      box-shadow: 0px 0px 16px -1px #d9ce6a;
    }
    span {
      @apply text-[16px] lg:text-[17px] xl:text-[18px] md:leading-[38px];
    }
  }
  .boxButton {
    @apply w-full flex justify-start;
    button {
      @apply text-[12px] md:text-[16px] lg:text-[18px] font-regular h-[32px] md:h-[40px]  lg:h-[46px] 
      justify-center items-center flex border border-[#FFFFFF] rounded-[6px] transition ease-in-out delay-150  duration-300 hover:border-[#d9ce6a] 
      hover:text-[#d9ce6a] hover:scale-110 mt-[25px];
    }
  }
}
