.wrapper {
  @apply w-full flex justify-center  mt-10 mb-16 lg:mt-14 lg:mb-20;
  .container {
    @apply flex flex-col w-[350px] md:w-[500px] xl:w-[730px] items-center;
    .title {
      @apply text-center  text-3xl  md:text-4xl xl:text-5xl font-thin tracking-widest;
    }
    .membersTitle {
      @apply text-[#29D7DF]  text-lg text-center lg:text-xl xl:text-2xl tracking-widest;
    }
    .boxAllSocialMedia {
      @apply pb-[25px] w-full;
      .boxSocialmedia {
        @apply flex flex-col mt-[32px] lg:mt-[73px] items-center;
        .icon {
          @apply w-[44px] h-[44px] md:w-[54px] md:h-[54px] xl:w-[100px] xl:h-[100px];

          svg {
            fill: white;
          }
        }
        .boxData {
          @apply flex mt-[16px] xl:mt-[32px];
          .data {
            @apply flex flex-col;
          }
          button {
            @apply h-[28px] w-auto min-w-[70px] xl:h-[36px] xl:min-w-[100px] text-center text-[14px] xl:text-[18px] font-bold rounded-[4px] lg:rounded-full text-[#D9CE6A]  border lg:border-2 border-[#D9CE6A]  px-2;
          }
        }
      }
    }
  }
}
