.video {
  grid-area: player;
  aspect-ratio: 16 / 9;


  @apply overflow-hidden order-2 self-center w-full lg:order-4 relative rounded-[10px] md:mb-0 cursor-pointer lg:self-end;
  .innerVideo {
    @apply relative rounded-[10px] overflow-hidden;
    video {
      @apply rounded-[10px];
    }
  }
  .thumbnail {
    @apply transition ease-in-out delay-150  duration-500 rounded-[10px];
  }
  &:hover {
    .thumbnail {
      @apply scale-110;
    }
  }
}

.youtubePlayerContainer{
  @apply  rounded-[12px] overflow-hidden;
}
.sliderArrowLeft{
  // @apply h-[120px] lg:h-[130px] xl:h-[140px];
  // @apply h-[20px] lg:h-[30px] xl:h-[40px];
}
.sliderArrowRight{
    // @apply h-[20px] lg:h-[30px] xl:h-[40px];
}
