.wrapper {
  @apply mx-[-10px] pl-0 sm:mx-0;
  .title {
    @apply text-center xl:text-[48px] md:text-[36px] text-[31px] mb-[40px] md:mb-[50px] lg:mb-[65px];
    .howToEarnTitle {
      @apply text-center text-3xl md:text-4xl xl:text-5xl font-thin tracking-widest mb-[48px] md:mb-[66px];
    }
  }
  .title_fr {
    @apply text-center xl:text-[48px] md:text-[36px] text-[28px] mb-[40px] md:mb-[50px] lg:mb-[65px];
    .howToEarnTitle {
      @apply text-center text-3xl md:text-4xl xl:text-5xl font-thin tracking-widest mb-[48px] md:mb-[66px];
    }
  }
  .boxCards {
    @apply flex flex-col items-center md:flex-row md:gap-[15px] justify-center flex-wrap;
  }
}
